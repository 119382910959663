<template>
  <core-section id="what-ive-done">
    <abstract-3 />

    <core-heading>What I've done.</core-heading>

    <v-col cols="12">
      <v-carousel
        :cycle="false"
        :height="650"
        :show-arrows="false"
        class="elevation-0"
        hide-delimiter-background
      >
        <v-carousel-item
          v-for="(project, i) in projects"
          :key="i"
        >
          <project :value="project" />
        </v-carousel-item>
      </v-carousel>
    </v-col>
  </core-section>
</template>

<script>
  export default {
    name: 'WhatIveDone',

    components: {
      Abstract3: () => import('@/components/Abstract3'),
      Project: () => import('@/components/Project'),
    },

    data: () => ({
      projects: [
        {
          name: 'Full System Redesign',
          category: 'Lead Development',
          details: 'As the lead developer on this product, I successfully led the team in redeveloping our existing 7 codebases, updating all of the code with new technologies, and consolidating our 7 products into one codebase with one database.',
          src: 'rolr-redesign.jpg',
          client: 'GTR',
          dates: '2019 - 2020',
          tags: 'Front End, Back End, Mobile App, API, Leadership',
        },
        {
          name: 'API Integrations',
          category: 'Development',
          details: 'I have successfully completed a multitude of API integrations with numerous well-known API providers such as SalesForce, Marketo, Eventbrite, Pardot, and many more.',
          src: 'api-integrations.jpg',
          client: 'Multiple',
          dates: '2016 - 2020',
          tags: 'Back End, API',
        },
        {
          name: 'Mobile App Rewrite',
          category: 'Lead Development',
          details: 'As a lead developer, I oversaw and contributed to the redevelopment of our 2 mobile apps from Objective-C and Java to Flutter.',
          src: 'mobile-app-redesign.jpg',
          client: 'GTR',
          dates: '2019 - 2020',
          tags: 'Mobile App, Leadership',
        },
        {
          name: 'FICO World 2016 - 2020 Registration Sites',
          category: 'Development',
          details: 'I was responsible for the programming of FICO\'s annual FICO World events. These events would typically see thousands of people register and take in over $1 million dollars on our site annually.',
          src: 'fico-world.jpg',
          client: 'FICO',
          dates: '2016 - 2020',
          tags: 'Front End, Back End',
        },
        {
          name: 'Tableseating Software',
          category: 'Development',
          details: 'I was in charge of the development and maintenance of a web app for the prestigious Columbia Business School\'s Annual Dinner. This web app was responsible for the seating arrangements and table capacity of over 100 tables and over 1000 table guests.',
          src: 'tableseating.jpg',
          client: 'Colombia Business School',
          dates: '2016 - 2018',
          tags: 'Front End, Back End',
        },
        {
          name: 'Agenda Builder',
          category: 'Lead Development',
          details: 'For Premier Breakthroughs\' annual event, I led my team and contributed to the creation of an agenda builder module that would allow people to select various sessions to attend at the event after completing their registration. This module kept track of session capacity and tied into our scanner mobile apps, which would indicate if someone could enter a session or not based on the barcode on their badge.',
          src: 'premier-agenda-builder.jpg',
          client: 'Premier',
          dates: '2019 - 2020',
          tags: 'Front End, Back End, Leadership',
        },
      ],
    }),
  }
</script>
